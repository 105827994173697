import React from "react"
import { navigate } from "gatsby"
import { getAuth, signOut } from "firebase/auth"
// import BlockContent from "@sanity/block-content-to-react"

/* Import Global Hook(s) */
import { useVipPage, useProgramEventsVipSortedByDay } from "~queries/hooks"
import useFirebaseUser from "~hooks/useFirebaseUser"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import VipPage from "~components/pages/vip/vipPage/vipPage" // CSS Order Issue
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Slideshow from "~components/slideshow/slideshow"

/* Import Page Components */
import ProgramDay from "~components/pages/program/day/day"
import Benefit from "~components/pages/vip/benefit/benefit"

/* Import Local Style(s) */
import "./vip-account.scss"

const VIPAccount = ({ location }) => {
  const firebaseUser = useFirebaseUser()
  const {
    _rawPageHeaderCopy,
    displayVIPBenefits,
    vipBenefits,
    _rawMoreCopy,
    slideshow,
  } = useVipPage()
  const programEventsVipSortedByDay = useProgramEventsVipSortedByDay()

  if (!firebaseUser && location.pathname !== `/vip`) {
    if (typeof window !== `undefined`) {
      navigate("/vip")
      return null
    }
  }

  const logout = () => {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      })
  }

  return (<div></div>)

  /*return (
    <VipPage title="VIP Account" header="VIP" location={location}>
      {slideshow.images.length > 0 ? <Slideshow slideshow={slideshow} /> : null}
      <div className="header__copy">
        <BlockContent blocks={_rawPageHeaderCopy} />
      </div>
      {programEventsVipSortedByDay.length > 0 && (
        <>
          <h2>Miami Beach 2021 Program</h2>
          {programEventsVipSortedByDay.map(({ date, events }) => (
            <PageArticleSection className="day" key={date}>
              <ProgramDay date={date} events={events} />
            </PageArticleSection>
          ))}
        </>
      )}
      {displayVIPBenefits && (
        <>
          <h2>VIP Benefits</h2>
          <h3>Around Miami</h3>
          <div className="benefits">
            {vipBenefits.map(benefit => (
              <Benefit benefit={benefit} key={benefit._key} />
            ))}
          </div>
        </>
      )}
      {_rawMoreCopy && (
        <>
          <h2>More</h2>
          <div className="more__copy">
            <BlockContent blocks={_rawMoreCopy} />
          </div>
        </>
      )}
      <button onClick={logout}>Sign Out</button>
    </VipPage>
  )*/
}

export default VIPAccount
